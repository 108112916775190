import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noimage'
})
export class NoimagePipe implements PipeTransform {

  transform(image: any[]): string {

    if ( !image ) {
      return 'ruta de la imagen por dedault';
    }
    if ( image.length > 0 ) {
      return image[0].url;
    } else {
      return 'ruta de la imagen por dedault';
    }
    return null;
  }

}
