import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule  } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
// import { PdfViewerModule } from 'ng2-pdf-viewer';

// RUTAS
import { AppRoutingModule } from './app.routing';

// SERVICIOS
import { AtencionesService } from './services/atenciones.service';

// COMPONENTES
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component'; // tiene su propio app.module
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from './components/components.module';

// Pipes personalizado
import { NoimagePipe } from './pipes/noimage.pipe';




@NgModule({
  imports: [
    BrowserAnimationsModule,
    HttpModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule.forRoot(),
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot()
    // PdfViewerModule
  ],
  providers: [
    AtencionesService
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    NoimagePipe

  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
