import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
// import 'rxjs/add/operator/map/Rx';
// import 'rxjs/Rx';
import { Observable, Subject, throwError } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';
import { GLOBAL } from './global';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginService } from './login.service';

@Injectable()
export class AtencionesService {

  public idempresa: number;
  public url: string;
  public preURL = '/atenciones'; // + this.idempresa;
  public preURLs = '/orden/list'; // + this.idempresa;

  getIdEmpresa(_idempresa) {
    this.idempresa = _idempresa;
    return this.idempresa;
  }


  constructor(private _httpClient: HttpClient,
              private _http: Http,
              private _login: LoginService) {
    // console.log(GLOBAL.url_slim + this.preURL);
  }
  /*
        postPresupuesto(presupuesto: any) {
          const newpres = JSON.stringify(presupuesto);
          const headers = new Headers({
            'Content-Type': 'application/json'
          });
          return this._http.post(this.preURL, newpres, {headers}).map(
            res => {
                  console.log(res.json());
                  return res.json;
            }
          );
        }

  getAtenciones(fini: string, ffin: string, empresa?: number) {
    const param = '?fechain=' + fini + '&fechafin=' + ffin + '&idempresa=45';
    return this._http.get(GLOBAL.url_slim + this.preURL + param)
      .pipe(map(res => res.json())); // funciona calvaq

  }*/

  getAtenciones(page = null, fini: string, ffin: string,  empresa?: number) {
    const param = '&fechain=' + fini + '&fechafin=' + ffin + '&idempresa=45';
    // return this._http.get(GLOBAL.url_sfy + this.preURLs + param)
    //   .pipe(map(res => res.json())); // funciona calvaq
      const params = 'authorization=' + this._login.getToken();
      const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});

      if ( page == null) {
        page = 1;
      }

      return this._httpClient.post( GLOBAL.url_sfy + this.preURLs + '?page=' + page + param, params ,  { headers } )
                .pipe(map( data => {
                  console.log(data);
                  // return data['data'];
                  return data;
                }));

  }

  getAtencion( idcom: number) {
    return this._http.get(GLOBAL.url_slim + this.preURL)
      .pipe(map(res => res.json())); // funciona calvaq

  }

  getCountTipo() {
    const params = 'authorization=' + this._login.getToken();
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return this._httpClient.post( GLOBAL.url_sfy + '/orden/counttipo' , params ,  { headers } )
                .pipe(map( reponse => {
                  return reponse;
                }));
  }

  buscarTrabajadores(termino: string): any { // del tipo atenciones
    // console.log(termino);
    // let heroesArr: Heroes[] = [];
    // termino = termino-toLowerCase();
    // for( let heroe of this.heroes){
    //   let nombre = heroe.nombre.toLowerCase();
    //   if (nombre.indexOf(termino) >= 0) {
    //     heroesArr.push( heroe );
    //   }
    // }
    // return heroesArr ;
  }


  /*
        getAtencion(id$: any) {
          const url = `${this.preURL}/${id$}`;

          return this._http.get(url).map(
              resp => resp.json());

        }

        putPresupuesto(presupuesto: any, id$: string) {
          const newpre = JSON.stringify(presupuesto);
          const headers = new Headers({
                'Content-type': 'application/json'
          });

          const url = `${this.preURL}/${id$}.json`;

          return this._http.put(url, newpre, {headers}).map(
              res => {
                console.log(res.json);
                return res.json();
              }
          );

        }

        delPresupuesto ( id$: string ) {
          const url = `${ this.preURL }/${ id$ }.json`;
          return this._http.delete( url )
          .map( res => res.json());
          }
  */
}
