export const GLOBAL = {
    //  url_slim: 'http://localhost/newcsr/webservice/slim/apilez/v1/public',
    //  url_sfy: 'http://localhost:8001/web/app_dev.php'

   url_slim: 'http://192.168.1.11/api/Slim/apilez/v1/public',
   // url_sfy: 'http://localhost:8001/app_dev.php'
   // Server de produccion
   url_sfy: 'http://190.117.202.204:8900'

    // url_sfy: 'http://190.117.202.204:8100/app_dev.php'

};
