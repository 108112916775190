import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';

import { Observable, Subject, throwError } from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public url: string;
  public identity;
  public token;

  constructor(
              private _http: Http
              ) {
                this.url = GLOBAL.url_sfy;
               }

  singUp(user_to_login) {
    const json = JSON.stringify(user_to_login);
    const params = 'json=' + json;
    console.log(params);
    const headers = new Headers({'Content-Type': 'application/x-www-form-urlencoded'});

    return this._http.post(this.url + '/login', params, { headers : headers})
                      .pipe(map(res => res.json()));
  }

  getIdentity() {
    const identity = JSON.parse(localStorage.getItem('identity'));
    if ( identity !== 'undefined') {
        this.identity = identity;
    }{
      this.identity = null;
    }
    return identity;
  }
  getToken() {
    const token = JSON.parse(localStorage.getItem('token'));
    if ( token !== 'undefined') {
        this.token = token;
    }{
      this.token = null;
    }
    return token;
  }

}
