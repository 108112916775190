import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Lezama Consultores';
  constructor(
              private _login: LoginService
              ) {

  }
  ngOnit() {
    console.log('Cargando desde app component');
    console.log( this._login.getIdentity() );
    console.log(this._login.getToken());
  }
}
